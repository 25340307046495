.title-bar-container {
  display: flex;
  padding: 24px 14px;
  margin-top: 9px;
  border: solid grey;
  border-width: 0px;
  border-bottom-width: 1px;
  border-color: lightgrey;
  flex-direction: row;
  justify-content: space-between; }
  .title-bar-container .title {
    font-size: 18px;
    font-weight: bold;
    text-align: 1px 1px #0001;
    color: black; }
