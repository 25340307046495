.landing-page .landing-content {
  width: calc(100% - 225px);
  float: left;
  height: 100vh;
  max-height: 100vh;
  overflow: auto;
  position: relative; }
  .landing-page .landing-content .title-user-container {
    margin-top: 9px;
    position: absolute;
    font-size: 18px;
    top: 0;
    right: 11px;
    padding: 24px 14px;
    border-bottom-width: 1px;
    background: white; }
    .landing-page .landing-content .title-user-container .user {
      text-align: right;
      cursor: pointer; }
    .landing-page .landing-content .title-user-container .list-container {
      margin-top: 5px;
      border: 1px solid #d6d6d6;
      padding: 4px; }
      .landing-page .landing-content .title-user-container .list-container .list-item {
        padding: 5px;
        background: #fff;
        cursor: pointer;
        border-bottom: 1px solid #d6d6d6;
        margin-bottom: 3px;
        transition: all 0.3s; }
        .landing-page .landing-content .title-user-container .list-container .list-item:hover {
          background: #f0f0f0;
          /* font-weight: bold; */
          transform: scale(1.01); }
    .landing-page .landing-content .title-user-container .item {
      font-size: 1.5rem; }

@media screen and (max-width: 1025px) {
  .landing-content {
    width: calc(100% - 180px) !important; } }

@media screen and (max-width: 800px) {
  .landing-content {
    width: calc(100% - 120px) !important; } }
