.side-drawer-container {
  display: flex;
  width: 224px;
  transition: all 0.2s;
  height: 100vh;
  justify-content: space-between;
  flex-direction: column;
  background: white;
  color: black;
  float: left;
  border-right: 1px solid black; }
  .side-drawer-container .item {
    cursor: pointer;
    padding: 8px 8px;
    border-width: 1px;
    display: flex;
    align-items: center;
    text-shadow: 1px 1px #0001; }
    .side-drawer-container .item i {
      padding: 16px;
      font-size: 28px; }
    .side-drawer-container .item img {
      width: 50px;
      height: 50px;
      background: white; }
    .side-drawer-container .item.selected {
      background: #44444445;
      font-weight: bold;
      text-shadow: 1px 1px 0px #f4f4f4; }
  .side-drawer-container .upper-part .item:last-child {
    border-bottom: 0; }
  .side-drawer-container.hidden {
    left: -225px; }
  .side-drawer-container .logo-container {
    border-bottom: solid black;
    border-width: 1px;
    padding: 16px 24px;
    display: flex;
    align-items: center; }
    .side-drawer-container .logo-container img {
      width: 55px;
      height: 55px;
      border: 1px solid black;
      border-radius: 50%; }
    .side-drawer-container .logo-container span {
      margin-left: 30px;
      font-size: 23px;
      margin-top: 5px; }
      .side-drawer-container .logo-container span pre {
        color: black;
        font-size: 14px;
        margin-bottom: 0px;
        margin-top: 3px;
        padding-left: 2px; }

@media screen and (max-width: 1016px) {
  .side_drawer-container.show {
    box-shadow: 7px 0px 12px 4px rgba(45, 54, 90, 0.380392); } }

@media screen and (max-width: 1025px) {
  .side-drawer-container {
    width: 179px; }
    .side-drawer-container .logo-container {
      padding: 16px 8px; }
      .side-drawer-container .logo-container span {
        margin-left: 7px;
        font-size: 18px; }
        .side-drawer-container .logo-container span pre {
          font-size: 11px; }
    .side-drawer-container .item {
      font-size: 11px; }
      .side-drawer-container .item i {
        padding: 0;
        font-size: 19px;
        padding-right: 5px; } }

@media screen and (max-width: 800px) {
  .side-drawer-container {
    width: 119px; } }
